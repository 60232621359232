// About.js
import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class SenseiRavey extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Sensei Graham Ravey - World Chief Instructor</h1>

                <div className="libraryImages">
                    <StaticImage src="../../images/senseiRavey.jpg" alt="Sanchin"/>
                </div>


                <p>Sensei Graham Ravey was born on August 25, 1953 in a small town in England Chesterfield. When Graham
                    was 14 years old he became very interested in studying Karate. Chesterfield was small there were not
                    many styles to choose from. Eventually Graham found a style called Wado Ryu. He started Wado Ryu
                    Karate in 1968. The chief instructor was Sensei Phil Milner who believed that before a man can be
                    taught to punch he should be able to take one. So over the next few years as well as learning the
                    basics of karate Graham was given a toughening up regime to forge ones's body, such excercises
                    included running bare footed with no Gi top on when snow lay on the ground in winter, crawling
                    through mud and nettle patches on training camps and standing in circles delivering blows to the
                    person next to you, also sparring multiple attackers. After Graham's first lesson he informed his
                    mother that one day he would go to Japan and study karate, his mother laughed at him for saying such
                    a thing. When Graham was 18 years old in 1971 he changed over to a style called Goju Kai. This was
                    being taught in Sheffield by Sensei Steve Bellamy. Steve had initially started out with Phil Milner
                    but changed to Goju Kai after seeing Sensei Brian Waites give a Karate demonstration in London.
                    Sensei Waites had lived and trained in Japan with Sensei Gogen Yamaguchi for one year. </p>

                <p>At the age of 20 Graham decided it was time to go to Japan and seek out some of the old values of
                    traditional karate. This time he told his mother he was going and she no longer laughed. After
                    arriving in Japan and within the first week he was introduced to Sensei Morio Higaonna. Graham was
                    so impressed with him that he immediately changed over to studying Goju Ryu. For one whole year
                    Sensei Higaonna made Graham train every day for about five hours and after this time he was allowed
                    to take his Black Belt 1st Dan. By this time Graham had been training for over six years. He had
                    only intended to stay in Japan for one year but that one year eventually stretched into twelve
                    years. During this time he once won the annual Yoyogi Dojo black belt Kumite competition and also
                    represented the Dojo as a team member at the annual Shibuya-Ku all styles Karate Tournament hosted
                    in the Budokan. Graham was the only non Japanese fighter that day. He met and trained with some
                    martial arts celebrities such as Benny Erquidez, Monster man Eddie, Huang Jang Lee. He was selected
                    to give the Japanese karate team some sparring practice with foreigners before the international
                    championships held in long beach California 1977. All these stories have been documented in a book
                    Graham published titled ''Yoyogi Dojo '74''</p>

                <p>Graham worked his way through his grades to 4th Dan - all grades being examined by Sensei Higaonna
                    and all grades being taken in Japan. Graham is the only foreigner to do this.</p>

                <p>In 1987 Sensei Graham Ravey was appointed Chief Instructor/Technical Advisor of Australia and he left
                    Japan to take up permanent residence in Australia.</p>

                <p>Later on, Sensei Ravey established the T.O.G.K.A to preserve the old ways of Okinawa Karate.</p>


                <h3>Japanese training schedule of Sensei Ravey in Yoyogi Dojo, Tokyo, Japan 1978-1982.</h3>


                <table border="1">
                    <tr>
                        <th>MONDAY</th>
                        <th>TUESDAY</th>
                        <th>WEDNESDAY</th>
                        <th>THURSDAY</th>
                        <th>FRIDAY</th>
                        <th>SATURDAY</th>
                        <th>SUNDAY</th>
                    </tr>
                    <tr>
                        <td>5 hours</td>
                        <td>5 hours</td>
                        <td>5 hours</td>
                        <td>3 hours</td>
                        <td>5 hours</td>
                        <td>3 hours</td>
                        <td>5 hours</td>
                    </tr>
                    <tr>
                        <td>Stretching</td>
                        <td>Personal training</td>
                        <td>Stretching</td>
                        <td>Personal training</td>
                        <td>Stretching</td>
                        <td>Personal training</td>
                        <td>Personal training</td>
                    </tr>
                    <tr>
                        <td>Strength</td>
                        <td>Kata</td>
                        <td>Strength</td>
                        <td>Kata</td>
                        <td>Strength</td>
                        <td>Kata</td>
                        <td>Kata</td>
                    </tr>
                    <tr>
                        <td>Fighting</td>
                        <td>Weights</td>
                        <td>Fighting</td>
                        <td>Weights</td>
                        <td>Fighting</td>
                        <td>Stretching</td>
                        <td>Stretching</td>
                    </tr>
                    <tr>
                        <td>Higaonna</td>
                        <td>Higaonna</td>
                        <td>Higaonna</td>
                        <td>Stretching</td>
                        <td>Higaonna</td>
                        <td>Skipping</td>
                        <td>Skipping</td>
                    </tr>
                    <tr>
                        <td>Karate</td>
                        <td>Karate</td>
                        <td>Skipping</td>
                        <td>Skipping</td>
                        <td>Karate</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Skipping</td>
                        <td>Kata</td>
                        <td>Stretching</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>


                <h3>In Sensei Graham Ravey's own words</h3>

                <p>From 1973 to 1987 I was taught by Sensei Morio Higaonna then 7th Dan. During personal training which
                    was usually in the mornings, Sensei Higaonna would sometimes call me over and we would train
                    together. I trained on Saturday and Sunday for about two years until I sort of "Got a Life" in
                    Japan. I trained in Parks and a shrine situated one minute walk from my doorstep. In my eyes I was
                    blessed. On Monday, Wednesday and Friday Bert Valetin Johansson ("The Swede") was my fighting coach.
                    Bert had lived in Japan for many years before I arrived. He trained only fighting and power training
                    at the Yoyogi Dojo outside the scheduled Karate sessions. He had a significant input into my Kumite
                    ability. I also continued to work out with Sensei Steve Bellamy once to twice a week.</p>
            </Layout>);
    }
}

export default SenseiRavey;
